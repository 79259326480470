/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Lora_400Regular = require('./Lora_400Regular.ttf');
export const Lora_500Medium = require('./Lora_500Medium.ttf');
export const Lora_600SemiBold = require('./Lora_600SemiBold.ttf');
export const Lora_700Bold = require('./Lora_700Bold.ttf');
export const Lora_400Regular_Italic = require('./Lora_400Regular_Italic.ttf');
export const Lora_500Medium_Italic = require('./Lora_500Medium_Italic.ttf');
export const Lora_600SemiBold_Italic = require('./Lora_600SemiBold_Italic.ttf');
export const Lora_700Bold_Italic = require('./Lora_700Bold_Italic.ttf');
